@import "../node_modules/bootstrap/scss/variables";


@font-face {
  font-family: 'League Spartan';
  font-display: swap;
  src:  url("/assets/fonts/LeagueSpartan-VariableFont_wght.ttf") format('truetype');
}

@font-face {
  font-family: 'Merriweather';
  font-weight: 300;
  font-display: swap;
  src:  url("/assets/fonts/Merriweather-LightItalic.ttf") format('truetype');
}


* {
  font-family: 'League Spartan', sans-serif;
}

h1,
h2, 
h3, 
h4, 
h5, 
h6 {
  font-weight: bold;
}

.divider {
  border: none;
  padding: .1em;
  height: .1em;
  width: 12rem;
  opacity: 1;

  color: $primary;
  background-color: $primary;  
}

.card-cover {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.feature-icon-small {
  width: 3rem;
  height: 3rem;
}

.drop-shadow {
  filter: drop-shadow(.5rem .5rem .5rem rgb(0, 0, 0))
}

.card {
  border: none;
}

.card-footer {
  border:none;
  background-color: white;
}

.cta {
  background-image: url("/assets/img/background2.webp");
  background-color: #000;
  background-repeat: no-repeat, repeat;
  background-position: center; 
  min-height:300px;
  color: white;
}

.cta h3 {
  font-size: 3rem;
  font-weight: bold;
}

footer h5,
footer a {
  font-size: .75em;
}

.stars {
  color: gold;
}